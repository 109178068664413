:root {
  --clr-black: #020203;
  --clr-white: #ffffff;
  --clr-main-1: #db003d;
  --clr-main-2: #99022c;
  --clr-secondary-1: #707070;
  --clr-secondary-2: #aaaaaa;
  --clr-secondary-3: #d8d8d8;
  --clr-secondary-4: #f6f6f6;
  --clr-error: #eb0000;
  --clr-warn: #ffb100;
  --clr-success: #1bb757;
  --clr-side-a: #cceefc;
  --clr-side-b: #fffccd;
}

.selected {
  background-color: var(--clr-side-a) !important;
  font-size: 17px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 /* *{outline: red 1px solid;} */